'use client'

import { Fragment } from 'react'

import { Icon } from '@circlefin/components/lib/Icon'
import { SelectList } from '@circlefin/components/lib/SelectList'
import { TextLink } from '@circlefin/components/lib/TextLink'
import { Menu, Transition } from '@headlessui/react'
import classNames from 'classnames'
import Link from 'next/link'

export interface DropdownProps {
  /**
   * Label for the dropdown trigger button.
   */
  label: string
  /**
   * List of dropdown options to render. Each option has a label and href.
   */
  options: {
    /**
     * Label for menu item representing the option.
     */
    label: string
    /**
     * Internal link href for menu item representing the option.
     */
    href: string
  }[]
  /**
   * Variant of the dropdown.
   * Default is 'header'.
   */
  variant?: 'header' | 'page'
}

export const Dropdown: React.FC<DropdownProps> = ({
  label,
  options,
  variant = 'header',
}) => {
  return (
    <Menu as="div" className="relative group" data-testid="dropdown">
      <Menu.Button as="div" data-testid="dropdown-button">
        <TextLink
          className={classNames({
            'border border-neutral rounded-sm px-4 py-2 text-neutral-strong type-body-base font-circular-regular hover:no-underline flex items-center justify-between whitespace-nowrap':
              variant === 'page',
          })}
          variant="primary"
        >
          {label}
          <Icon
            className={classNames(
              "group-data-[headlessui-state='open']:rotate-180 transition-transform duration-100 ease-in-out",
              {
                'text-interactive': variant === 'header',
                'hover:no-underline text-icon-neutral': variant === 'page',
              },
            )}
            name="ChevronDownSolid"
          />
        </TextLink>
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className="absolute left-0 mt-2 origin-top-left z-50 w-fit"
          data-testid="dropdown-list"
        >
          <SelectList className="w-fit bg-white rounded-sm shadow-lg">
            {options.map(({ href, label }) => (
              <Menu.Item key={label + href} as="div" className="group">
                <Link href={href}>
                  <SelectList.Item
                    className={classNames(
                      "group-data-[headlessui-state='active']:bg-primary-subtle-hovered text-neutral-strong",
                      {
                        'whitespace-nowrap': variant === 'header',
                      },
                    )}
                    data-testid="dropdown-item"
                    item={{
                      label: label,
                      value: {
                        id: label + href,
                      },
                    }}
                  />
                </Link>
              </Menu.Item>
            ))}
          </SelectList>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}
