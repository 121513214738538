import { Chip } from '@circlefin/components/lib/Chip'
import { Modal } from '@circlefin/components/lib/Modal'
import { SkeletonBox } from '@circlefin/components/lib/SkeletonBox'
import { capitalize } from '@shared/utils'

import type { OperationInfoFragment } from '@features/playground.graphql'

/**
 * Props for the Header component.
 */
export interface HeaderProps {
  /**
   * The operation information to be displayed in the header.
   * If null or undefined, placeholder content will be shown.
   */
  operation?: OperationInfoFragment | null
  /**
   * Indicates whether the component is in a loading state.
   * When true, skeleton boxes will be displayed instead of actual content.
   */
  loading: boolean
}

/**
 * Header component for displaying operation information.
 * This component shows the operation summary, method, and path.
 * It also includes a close button for the modal.
 */
export const Header: React.FC<HeaderProps> = ({ loading, operation }) => {
  return (
    <header
      className="w-full p-6 flex justify-between items-center border-b border-neutral-subtle h-28 flex-shrink-0"
      data-testid="playground-header"
    >
      <div>
        <SkeletonBox
          className="w-152 h-7 mb-1"
          data-testid="skeleton-box"
          loading={loading}
        >
          {operation && (
            <h2 className="text-neutral-strong text-lg font-circular-bold mb-1">
              {operation.summary}
            </h2>
          )}
        </SkeletonBox>
        <SkeletonBox
          className="w-80 h-7"
          data-testid="skeleton-box"
          loading={loading}
        >
          {operation && (
            <div>
              <Chip variant="default/neutral">
                {capitalize(operation.method)}
              </Chip>
              <span className="inline-block ml-2 text-neutral-subtle">
                {operation.path}
              </span>
            </div>
          )}
        </SkeletonBox>
      </div>
      <div className="relative w-8 h-8">
        <Modal.Close className="top-0 left-0 m-0 text-blue-600" />
      </div>
    </header>
  )
}
