'use client'

import { type FC } from 'react'

import { useReadQuery } from '@shared/apollo'
import useTranslation from 'next-translate/useTranslation'

import { NoResults } from '../NoResults'
import { ResultItem } from '../ResultItem/ResultItem'

import type {
  ResultItemApiMethods,
  ResultItemProps,
} from '../ResultItem/ResultItem'
import type { SearchQuery, SearchResult } from '@features/algolia.graphql'
import type { QueryRef } from '@shared/apollo'

export interface ResultProps {
  /**
   * The query reference for the search query. This is a reference to the
   * GraphQL query that fetches the search results, and can be used to
   * execute the query and obtain the search data.
   */
  queryRef: QueryRef<SearchQuery>
}

export const Results: FC<ResultProps> = ({ queryRef }) => {
  const { t } = useTranslation('algolia')

  const { data } = useReadQuery(queryRef)

  if (data.search.results.length === 0) {
    return <NoResults searchKey={data.search.searchKey || ''} />
  }

  return (
    <div>
      <div className="type-label-md mt-2">{t`modals.search.title`}</div>
      {data.search.results.map((searchResultItem) =>
        searchResultItem ? (
          <ResultItem
            key={searchResultItem.objectID}
            {...getSearchPropsFromResultsData(searchResultItem)}
          />
        ) : null,
      )}
    </div>
  )
}

/**
 * Helper function to map SearchResult object to ResultItemProps.
 */
export function getSearchPropsFromResultsData(
  searchResultItem: SearchResult,
): ResultItemProps {
  return {
    description:
      searchResultItem.snippetResult?.value?.value ||
      searchResultItem.highlightResult?.value?.value ||
      searchResultItem.value ||
      '',
    href: searchResultItem.url,
    options:
      searchResultItem.origin === 'api' && searchResultItem.method != null
        ? {
            type: 'api',
            method: searchResultItem.method as ResultItemApiMethods,
          }
        : {
            type: ['Python', 'Node.js'].includes(searchResultItem.origin)
              ? (searchResultItem.origin as 'Python' | 'Node.js')
              : 'docs',
          },
    title: searchResultItem.title,
  }
}
