import { CWTag } from '@features/common.components/ComponentsWeb/Tag'
import classNames from 'classnames'
import Link from 'next/link'

import type { OperationSummary } from '@features/playground.graphql'

/**
 * Props for the OperationItem component.
 */
export interface OperationItemProps {
  /** The operation summary to display. */
  operation: OperationSummary
  /** Whether the operation is currently active. */
  active: boolean
  /** Callback function to be called when the operation is clicked. */
  handleClick?: (operation?: OperationSummary) => void
  /** Decide whether the operation should be rendered as a link. */
  link?: boolean
  /** Optional prefix to be used for the link. */
  prefix?: string
}

/**
 * OperationItem component displays a single API operation with its method and summary.
 * It can be clicked to select the operation.
 */
export const OperationItem: React.FC<OperationItemProps> = ({
  link,
  prefix,
  ...props
}) => {
  // Make sure strip the leading slash from the slug
  const href = `/${prefix}/${props.operation.slug.startsWith('/') ? props.operation.slug.slice(1) : props.operation.slug}`

  if (link) {
    return (
      <Link href={href}>
        <Item {...props} />
      </Link>
    )
  }

  return <Item {...props} />
}

const Item: React.FC<OperationItemProps> = ({
  active,
  operation,
  handleClick,
}) => {
  return (
    <button
      aria-pressed={active}
      className={classNames(
        'w-full flex items-start justify-start p-2 pl-6 rounded-sm hover:bg-primary-subtle-hovered text-neutral',
        {
          'bg-primary-subtle-hovered': active,
        },
      )}
      data-testid="operation-item"
      // eslint-disable-next-line react/jsx-no-bind -- Since this is a server component there is no downside using an arrow function here
      onClick={() => {
        handleClick?.(operation)
      }}
    >
      {operation.method && (
        <div className="w-16 flex-shrink-0 text-left">
          <CWTag
            className="uppercase rounded-md bg-bold-primary text-invert font-circular-bold text-xs"
            variant="neutral"
          >
            {operation.method}
          </CWTag>
        </div>
      )}
      <span
        className={classNames(
          'flex-1 pt-0.5 text-left text-sm truncate text-wrap',
          {
            'font-circular-bold': active,
          },
        )}
      >
        {operation.method
          ? operation.summary
          : operation.name.replace(/_/g, '_\u200B')}
        {/* Add zero-width space after underscore to allow line break */}
      </span>
    </button>
  )
}
