import { Fragment, type FC, useCallback } from 'react'

import classNames from 'classnames'
import { usePathname } from 'next/navigation'

import { NavItem } from '../NavItem/NavItem'
import { isItemActive } from '../utils'

import type { NavigationItem } from '../types'

export interface NavGroupProps {
  item: NavigationItem
  index: number
}

export const NavGroup: FC<NavGroupProps> = ({ item, index }) => {
  const pathname = usePathname()

  /**
   * Check if the item (or it's children when available) is active.
   */
  const isActive = useCallback(
    (item: NavigationItem) => isItemActive(item, pathname),
    [pathname],
  )

  if (item.isHidden) return null

  return (
    <Fragment>
      <NavItem
        className={classNames(
          'p-0.5 transition-gradient duration-300',
          { 'mt-4': index === 0 },
          {
            'border-blue-300 font-circular-bold': isActive(item),
          },
          {
            'hover:bg-primary-subtle-hovered': item.url !== pathname,
          },
        )}
        href={item.fallbackUrl || item.url}
        label={item.label}
      />
      {item.children?.map((groupItem, subIndex) => {
        if ('url' in groupItem) {
          if (groupItem.isHidden) return null
          return (
            <NavItem
              key={`${item.label}-${index}-${subIndex}`}
              className={classNames(
                'pl-6 p-0.5 transition-gradient duration-300',
                {
                  'bg-primary-subtle-hovered [&>*]:bg-transparent font-circular-bold':
                    isActive(groupItem),
                },
                {
                  'hover:bg-primary-subtle-hovered': groupItem.url !== pathname,
                },
              )}
              href={groupItem.url}
              label={groupItem.label}
            />
          )
        }
      })}
    </Fragment>
  )
}
