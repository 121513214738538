import { useCallback, useMemo } from 'react'

import { Dropdown } from '@circlefin/components/lib/Dropdown'
import { CWButtonCard } from '@features/common.components/ComponentsWeb/ButtonCard'
import { CWIcon } from '@features/common.components/ComponentsWeb/Icon'
import {
  isAnyOfField,
  isArrayField,
  isBooleanField,
  isIntegerField,
  isNumberField,
  isObjectField,
  isOneOfField,
  isStringField,
} from '@shared/openapi/guards'
import useTranslation from 'next-translate/useTranslation'

import {
  getInputTypeDescription,
  getOneOfTypeDescription,
} from '../../../InputType/InputType'

import type { DropdownProps } from '@circlefin/components/lib/Dropdown'
import type { Field } from '@shared/openapi/types'

export type OnSelect = (schema: Field.FieldType, defaultValue: unknown) => void

/**
 * Props for the AddNewButton component.
 */
export type AddNewButtonProps = {
  /**
   * Callback function that is called every time new selection has been made.
   * Will be called with selected schema and default value.
   */
  onSelect: OnSelect
  /**
   * Schema to derive available ArrayField options.
   */
  schema: Field.ArrayField
}

/**
 * Helper component for ArrayPrimitiveInputField.
 * @returns The rendered AddNewButton component.
 */
export const AddNewButton = ({ onSelect, schema }: AddNewButtonProps) => {
  const { t } = useTranslation('playground')
  const addLabel = t('form.input.arrays.add', {
    type:
      schema.items.type === 'oneOf'
        ? getOneOfTypeDescription(schema.items.fields, t)
        : getInputTypeDescription(schema.items, t),
  })

  const isOneOf = useMemo(() => isOneOfField(schema.items), [schema.items])
  const availableOptions = useMemo(
    () =>
      isOneOf && 'items' in schema && 'fields' in schema.items
        ? schema.items.fields.map((field, index) => ({
            label: String('title' in field ? field.title : field.type),
            value: index,
          }))
        : [],
    [isOneOf, schema],
  )

  const onAdd = useCallback(() => {
    onSelect(schema.items, getDefaultValue(schema.items))
  }, [onSelect, schema.items])

  const onStateChange = useCallback<
    Required<DropdownProps<number>>['onStateChange']
  >(
    (changes) => {
      if (changes?.selectedItem) {
        const index = changes.selectedItem.value!
        onSelect(
          (schema.items as Field.OneOfField).fields[index],
          getDefaultValue((schema.items as Field.OneOfField).fields[index]),
        )
      }
    },
    [onSelect, schema.items],
  )

  return isOneOf ? (
    <Dropdown
      items={availableOptions}
      onStateChange={onStateChange}
      placeholder={addLabel}
    >
      <Dropdown.Options items={availableOptions} />
    </Dropdown>
  ) : (
    <CWButtonCard
      className="my-1 rounded-sm border-neutral [&_button]:py-1.5 [&_button]:px-3"
      onClick={onAdd}
      type="button"
      hideArrow
    >
      <div className="w-full text-left text-base text-neutral-subtlest">
        {addLabel}
        <CWIcon className="float-right" name="PlusSolid" size={24} />
      </div>
    </CWButtonCard>
  )
}

function getDefaultValue(
  schema: unknown,
  useExample = true,
): boolean | string | number | unknown[] | object {
  if (isArrayField(schema)) {
    return []
  }
  if (isBooleanField(schema)) {
    return useExample ? schema.example : false
  }
  if (isStringField(schema)) {
    return useExample ? schema.example : ''
  }
  if (isIntegerField(schema) || isNumberField(schema)) {
    return useExample ? schema.example : 0
  }
  if (isOneOfField(schema) || isAnyOfField(schema)) {
    return getDefaultValue(schema.fields[0], false)
  }
  if (isObjectField(schema)) {
    const properties = schema.properties
    const defaultValues: Record<string, unknown> = {}
    Object.keys(properties).forEach((prop) => {
      defaultValues[prop] = getDefaultValue(properties[prop], false)
    })
    return defaultValues
  }
  return true
}
