'use client'
import type { FC, PropsWithChildren } from 'react'
import { useCallback } from 'react'

import { Button } from '@circlefin/components/lib/Button'
import { Icon } from '@circlefin/components/lib/Icon'
import { SearchInputField } from '@features/algolia.components/src/SearchInputField'
import { useScrollDirection } from '@features/common.hooks/useScrollDirection'
import { useFeatureFlag } from '@features/flags.hooks/useFeatureFlag'
import { Dropdown } from '@features/navigation.components/Dropdown'
import { useNavigation } from '@features/navigation.hooks/useNavigation'
import classNames from 'classnames'
import useTranslation from 'next-translate/useTranslation'

import { Link } from '../Link/Link'
import { NotificationBanner } from '../NotificationBanner'

import { Logo } from './Logo'
import { ScrollIndicator } from './ScrollIndicator'

interface HeaderProps extends PropsWithChildren {
  /**
   * Is the scroll indicator enabled.
   */
  enableScrollIndicator?: boolean
  /**
   * Title of the content.
   */
  title?: string
}

export const Header: FC<HeaderProps> = ({
  title,
  enableScrollIndicator = true,
}) => {
  const { t } = useTranslation('common')
  const scrollDirection = useScrollDirection()
  const [showNavigation, setShowNavigation] = useNavigation()
  const handleNavigationToggle = useCallback(() => {
    setShowNavigation((prev) => !prev)
  }, [setShowNavigation])
  const sdkAndSampleOverviewEnabled = useFeatureFlag(
    'sdkAndSampleOverviewEnabled',
  )
  const sdkExplorerEnabled = useFeatureFlag('sdkExplorerEnabled')

  const dropdownValues = [
    {
      label: t('header.links.apiPlayground'),
      href: '/api-reference',
    },
    sdkExplorerEnabled
      ? {
          label: t('header.links.sdkExplorer'),
          href: '/sdk-explorer',
        }
      : null,
    {
      label: t('header.links.sdk'),
      href: '/sdks',
    },
    {
      label: t('header.links.sampleProjects'),
      href: '/sample-projects',
    },
  ].filter((item): item is NonNullable<typeof item> => item !== null)

  return (
    <div
      className={classNames(
        'fixed z-30 w-full border-b border-neutral-subtle bg-white transition-all duration-500',
        {
          '-top-header md:top-0': !showNavigation && scrollDirection === 'DOWN',
          'top-0': scrollDirection === 'UP',
        },
      )}
      data-testid="header"
    >
      <NotificationBanner />
      <div className="w-full flex items-center px-6 py-5 h-20 gap-x-8">
        {title ? (
          <>
            <div
              className={classNames('md:block', {
                hidden: !showNavigation,
              })}
            >
              <Logo />
            </div>
            <p
              className={classNames(
                'md:hidden type-body-base-bold',
                { hidden: showNavigation },
                { block: !showNavigation },
              )}
            >
              {title}
            </p>
          </>
        ) : (
          <Logo />
        )}
        <div className="hidden md:block">
          <SearchInputField />
        </div>
        <div className="hidden md:flex md:items-center md:justify-end md:space-x-2 flex-1">
          {sdkAndSampleOverviewEnabled ? (
            <Dropdown
              label={t('header.links.apisAndSdks')}
              options={dropdownValues}
            />
          ) : (
            <Link path="/api-reference" type="internal">
              <Button
                className="normal-case"
                label={t('header.links.apiPlayground')}
                size="base"
                variant="text"
              >
                {t('header.links.apiPlayground')}
              </Button>
            </Link>
          )}
          <Link path="/" type="faucet">
            <Button
              className="normal-case"
              label={t('header.links.faucet')}
              size="base"
              variant="text"
            >
              {t('header.links.faucet')}
            </Button>
          </Link>
          <Link
            path="/release-notes"
            title={t('header.links.releaseNotes')}
            type="internal"
          >
            <Button
              className="normal-case"
              label={t('header.links.releaseNotes')}
              size="base"
              variant="text"
            >
              {t('header.links.releaseNotes')}
            </Button>
          </Link>
          <Link
            path="https://support.usdc.circle.com/hc/en-us/p/contactus"
            title={t('header.links.help')}
            type="external"
          >
            <Button
              className="normal-case"
              label={t('header.links.help')}
              size="base"
              variant="text"
            >
              {t('header.links.help')}
            </Button>
          </Link>
        </div>
        <div className="flex md:hidden justify-end space-x-2 flex-1">
          <Button onClick={handleNavigationToggle} size="sm" variant="text">
            {showNavigation ? (
              <Icon name="XSolid" size={18} />
            ) : (
              <Icon
                className="text-neutral-strong"
                name="MenuAlt2Outline"
                size={18}
              />
            )}
          </Button>
        </div>
      </div>
      {enableScrollIndicator ? <ScrollIndicator /> : null}
    </div>
  )
}
