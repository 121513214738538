import type { ChangeEventHandler } from 'react'
import { useCallback, useEffect, useRef } from 'react'

import { Input } from '@circlefin/components/lib/Input'
import useTranslation from 'next-translate/useTranslation'

/**
 * Props for the SearchField component.
 */
export interface SearchFieldProps {
  /**
   * The current search query.
   */
  query: string
  /**
   * Callback function to handle changes to the search query.
   */
  onQueryChange: (query: string) => void
}

/**
 * SearchField component provides an input field for search functionality.
 * It includes a search icon and a clear button when there's a query.
 * @param props - The component props.
 * @returns A React component rendering a search input field.
 */
export const SearchField: React.FC<SearchFieldProps> = ({
  onQueryChange,
  query,
}) => {
  const { t } = useTranslation('playground')
  const input = useRef<HTMLInputElement>(null)

  const handleSearch = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => {
      onQueryChange(event.target.value)
    },
    [onQueryChange],
  )

  const handleReset = useCallback(() => {
    if (input.current) input.current.value = ''
    onQueryChange('')
  }, [input, onQueryChange])

  /**
   * Initially set the query value since the Input field is not doing that automatically.
   */
  useEffect(() => {
    if (input.current) input.current.value = query
  })

  return (
    <Input
      className="w-full"
      placeholder={t`playground.sidebar.search`}
      value={query}
    >
      <Input.Field.Text ref={input} name="query" onChange={handleSearch}>
        {query !== '' ? (
          <Input.Inline.IconButton
            align="right"
            className="-mr-1"
            data-testid="input-icon-button"
            label="Clear"
            name="XSolid"
            onClick={handleReset}
          />
        ) : (
          <Input.Inline.Icon
            align="right"
            data-testid="input-icon"
            name="SearchOutline"
          />
        )}
      </Input.Field.Text>
    </Input>
  )
}
