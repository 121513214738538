'use client'
import type { FC, HTMLAttributeAnchorTarget } from 'react'

import { Sidenav } from '@circlefin/components/lib/Sidenav'
import Link from 'next/link'

// eslint-disable-next-line import/extensions
import type { IconGradientProps } from '@circlefin/components/lib/Icon/Icon.Gradient/Icon.Gradient'

export interface NavIconItemProps {
  /**
   * Custom styles.
   */
  className?: string
  /**
   * Navigation link url.
   */
  href: string
  /**
   * Navigation link label.
   */
  label: string
  /**
   * Navigation link icon.
   */
  icon: IconGradientProps['name']
  /**
   * If the link is active, default to false.
   */
  active?: boolean
  /**
   * Specifies where to open the linked page.
   */
  target?: HTMLAttributeAnchorTarget
}

export const NavIconItem: FC<NavIconItemProps> = ({
  className,
  href,
  icon,
  label,
  active,
  target,
}) => {
  return (
    <div className="h-full">
      <Link href={href} legacyBehavior passHref>
        <Sidenav.Item
          active={active}
          className={className}
          id={label}
          name={icon}
          target={target}
        >
          {label}
        </Sidenav.Item>
      </Link>
    </div>
  )
}
