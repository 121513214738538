import type React from 'react'
import { useCallback, type PropsWithChildren } from 'react'

import { Button } from '@circlefin/components/lib/Button'
import { useFormContext } from '@circlefin/form'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'

export type HeadingProps = {
  /**
   * The flag to display additional information.
   */
  showInfo?: boolean
}

/**
 * Heading component for parameter sections.
 * @returns A heading element with the main text and a "required" indicator.
 */
export const Heading: React.FC<PropsWithChildren<HeadingProps>> = ({
  showInfo,
  children,
}) => {
  const { t } = useTranslation('playground')
  const clearButtonLabel = t`form.clear`
  const { reset, trigger } = useFormContext()

  const resetForm = useCallback(() => {
    reset()
    void trigger()
  }, [reset, trigger])

  return (
    <h3 className="text-neutral-strong font-circular-bold text-base mb-3 space-x-2">
      <span>{children}</span>
      {showInfo && (
        <>
          <span className="text-neutral-subtle font-circular-regular text-sm">
            <Trans
              components={{
                em: <span className="text-error" />,
              }}
              i18nKey="playground:parameter.titles.required"
            />
          </span>
          <Button
            className="float-end px-1 py-0"
            label={clearButtonLabel}
            onClick={resetForm}
            variant="text"
          >
            {clearButtonLabel}
          </Button>
        </>
      )}
    </h3>
  )
}
