import type React from 'react'
import { useEffect, useState } from 'react'

import { useDebounce } from '@circlefin/hooks/useDebounce'
import { usePlaygroundState } from '@features/playground.hooks/usePlaygroundState'
import useTranslation from 'next-translate/useTranslation'

import { OperationTemplate } from '../../OperationTemplate'

import type { FormChangeEventPayload } from '@features/playground.constants/events'
import type { Operation, OperationInput } from '@features/playground.graphql'

/**
 * RequestTemplate component renders the operation template and manages its input state.
 * It listens for form change events and updates the template accordingly.
 * @returns The rendered RequestTemplate component.
 */
export const RequestTemplate: React.FC = () => {
  const { input, operation } = usePlaygroundState()
  const { t } = useTranslation('playground')

  const [debouncedInput, setDebouncedInput] = useState<OperationInput>({
    identifier: operation.slug,
    ...input,
  })

  /**
   * Debounced callback to handle form changes.
   * Updates the input state with the new data and operation..
   */
  const handleInputChange = useDebounce({
    callback: (data: FormChangeEventPayload, operation: Operation) => {
      setDebouncedInput({
        identifier: operation.slug,
        ...data,
      })
    },
    delay: 250,
  })

  useEffect(() => {
    handleInputChange(input, operation)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleInputChange, JSON.stringify(input), JSON.stringify(operation)])

  return (
    <OperationTemplate
      header={{
        title: t`playground.request`,
      }}
      input={debouncedInput}
    />
  )
}
