import { useCallback, useEffect, useMemo, useState } from 'react'

import { useFormContext } from '@circlefin/form'
import { CWButton } from '@features/common.components/ComponentsWeb/Button'
import { CWIcon } from '@features/common.components/ComponentsWeb/Icon'
import classNames from 'classnames'
import useTranslation from 'next-translate/useTranslation'

import { InputType } from '../InputType/InputType'
import { getInputLabel } from '../getInputLabel/getInputLabel'

import type { FormInputFieldProps, WithFormInputField } from '../types'
import type { Field } from '@shared/openapi/types'

export type ObjectInputFieldProps = FormInputFieldProps<Field.ObjectField> &
  WithFormInputField

export const ObjectInputField: React.FC<ObjectInputFieldProps> = ({
  schema,
  FormInputField,
  prefix,
  name,
  displayObjectBox = true,
}) => {
  const { t } = useTranslation('playground')
  const { resetField, trigger, getValues } = useFormContext()

  const isOptional = useMemo(() => {
    if (!displayObjectBox) return false

    return !schema.required
  }, [schema.required, displayObjectBox])

  const [isEnabled, setIsEnabled] = useState(!isOptional)

  const controlName = useMemo(
    () => [prefix, name].filter(Boolean).join('.'),
    [name, prefix],
  )

  const handleToggleEnable = useCallback(() => {
    setIsEnabled((prevState) => !prevState)

    if (isEnabled) {
      resetField(controlName)
    }
  }, [isEnabled, controlName, resetField])

  // If the object is optional, enabled, and empty, reset all fields and trigger validation.
  // This handles the case where the object is disabled and then enabled again, because the fields are not automatically reset and revalidated.
  useEffect(() => {
    const fieldValue = getValues(controlName) as unknown
    if (isOptional && isEnabled && fieldValue == null) {
      Object.keys(schema.properties).forEach((name) => {
        const fieldName = `${controlName}.${name}`
        resetField(fieldName)
        void trigger(fieldName)
      })
    }
  }, [
    isEnabled,
    controlName,
    schema.properties,
    trigger,
    isOptional,
    resetField,
    getValues,
  ])

  return (
    <div>
      {displayObjectBox && (
        <div className="mb-1 relative">
          <p className="type-body-sm-bold text-neutral">
            {getInputLabel({
              schema,
              name,
            })}
          </p>
          <InputType schema={schema} />
        </div>
      )}

      {isEnabled && (
        <div
          className={classNames('w-full space-y-4', {
            'border border-neutral rounded-md p-4': displayObjectBox,
          })}
          data-testid="inputfield-object"
        >
          {Object.keys(schema.properties).map((name) => {
            return (
              <FormInputField
                key={name}
                name={name}
                prefix={controlName}
                schema={schema.properties[name]}
              />
            )
          })}
        </div>
      )}

      {isOptional && (
        <CWButton
          className={classNames({
            'mt-2': isEnabled,
          })}
          onClick={handleToggleEnable}
          size="sm"
          variant="secondary"
        >
          {t(isEnabled ? 'form.disableField' : 'form.enableField', { name })}
          <CWIcon
            className="ml-1"
            name={isEnabled ? 'MinusSolid' : 'PlusSolid'}
            size={16}
          />
        </CWButton>
      )}
    </div>
  )
}
