'use client'
import { Modal } from '@circlefin/components/lib/Modal'

import { CodePanel } from '../CodePanel/CodePanel'

import type { CodePanelProps } from '../CodePanel/CodePanel'
import type { ModalProps } from '@circlefin/components/lib/Modal'

export type CodeModalProps = ModalProps &
  Omit<CodePanelProps, 'embedded' | 'identifier'>

export const CodeModal: React.FC<CodeModalProps> = ({
  code,
  open,
  className,
  ...props
}) => {
  return (
    <Modal
      className={className}
      data-testid="code-modal"
      open={open}
      {...props}
    >
      <Modal.Close className="top-4" />
      <Modal.Body className="overflow-auto pr-4 pl-4 -my-2" spaceForClose>
        <div className="w-full relative pt-1.5">
          <CodePanel
            canExpand={false}
            className="min-h-96"
            {...props}
            code={code}
          />
        </div>
      </Modal.Body>
    </Modal>
  )
}
