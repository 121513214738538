'use client'

import { useCallback, useEffect, useState } from 'react'

import { SkeletonBox } from '@circlefin/components/lib/SkeletonBox'
import { Statement } from '@circlefin/components/lib/Statement'
import {
  CWHeading,
  CWHeadingHeader,
  CWHeadingIntro,
} from '@features/common.components/ComponentsWeb/Heading'
import { ClientSelection } from '@features/playground.components/ClientSelection'
import { CodePanel } from '@features/playground.components/CodePanel'
import { OperationTemplateDocument } from '@features/playground.graphql'
import { useGlobalPlaygroundSettings } from '@features/playground.hooks/useGlobalPlaygroundSettings'
import { useLazyQuery } from '@shared/apollo'
import classNames from 'classnames'
import useTranslation from 'next-translate/useTranslation'

import type { ClientSelectionValue } from '@features/playground.components/ClientSelection'
import type { OperationInput } from '@features/playground.graphql'

export interface OperationTemplateProps {
  /**
   * Optional className that should be applied to the wrapper.
   */
  className?: string
  /**
   * The parameters that should be used to generate the template.
   */
  input?: OperationInput
  /**
   * Optional header configuration for the template.
   */
  header?: {
    /**
     * The title of the header.
     */
    title: string

    /**
     * Optional description for the header.
     */
    description?: string
  }
}

export const OperationTemplate: React.FC<OperationTemplateProps> = ({
  className,
  input,
  header,
}) => {
  const { t } = useTranslation('playground')
  const [initialLoadingDone, setInitialLoadingDone] = useState(false)
  const [operationTemplate, { data, error }] = useLazyQuery(
    OperationTemplateDocument,
    {
      fetchPolicy: 'cache-and-network',
      onCompleted: () => setInitialLoadingDone(true),
      onError: () => setInitialLoadingDone(true),
    },
  )

  const [{ client, language }, setGlobalSettings] =
    useGlobalPlaygroundSettings()

  const handleClientSelection = useCallback(
    (value: ClientSelectionValue) => {
      setGlobalSettings({
        client: value.client,
        language: value.lang,
      })
    },
    [setGlobalSettings],
  )

  useEffect(() => {
    if (input) {
      void operationTemplate({
        variables: {
          config: {
            client,
            language,
          },
          input,
        },
      })
    }
  }, [client, language, input, operationTemplate])

  return (
    <div className="w-full" data-testid="operation-template">
      <div className="w-full flex items-center justify-between mb-4">
        <div className="w-full">
          {header ? (
            <CWHeading variant="title/sm">
              <CWHeadingHeader>{header.title}</CWHeadingHeader>
              {header.description ? (
                <CWHeadingIntro>{header.description}</CWHeadingIntro>
              ) : null}
            </CWHeading>
          ) : null}
        </div>
        <ClientSelection
          onClientSelection={handleClientSelection}
          value={{
            client,
            lang: language,
          }}
        />
      </div>
      <div className={classNames('w-full relative', className)}>
        <SkeletonBox className="w-full h-48" loading={!initialLoadingDone}>
          {data?.operationTemplate?.code && (
            <CodePanel code={data.operationTemplate.code} />
          )}
          {error && (
            <div className="w-full border border-neutral-subtle rounded-sm flex items-center justify-center h-48">
              <Statement
                iconName="XSolid"
                status="error"
                subtitle={t`operationTemplate.error.subtitle`}
                title={t`operationTemplate.error.title`}
                variant="component"
              />
            </div>
          )}
        </SkeletonBox>
      </div>
    </div>
  )
}
