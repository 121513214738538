import * as y from 'yup'

import type { GetInputSchema } from '../types'
import type { Field } from '@shared/openapi/types'

export const getObjectSchema = (
  getInputSchema: GetInputSchema,
  schema: Field.ObjectField,
  required = false,
) => {
  let rule = y.object().default(null).nullable()
  if (required) {
    rule = rule.required()
  }
  rule = rule
    .shape(
      Object.keys(schema.properties).reduce((result, name) => {
        return {
          ...result,
          [name]: getInputSchema(schema.properties[name]),
        }
      }, {}),
    )
    .test(() => {
      /**
       * We want to always return true for objects and leave the validation up to the inner fields.
       * This allows us to handle required vs optional objects by being able to include or exclude the inner fields.
       * This is necessary because yup does not allow us to conditionally require fields when we don't know them ahead of time.
       * Example.
       * - An optional object has an optional field and a required field.
       * - We only want to validate the object when one of the object's fields have been touched.
       * - So we set the optional objects to null initially and always validate as true.
       * - The user can decide to enable the object which will add in the object's fields at which point they get validated.
       */
      return true
    })

  return rule as unknown as y.Schema
}
