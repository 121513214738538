import { useCallback } from 'react'

import { Dropdown } from '@circlefin/components/lib/Dropdown'
import useTranslation from 'next-translate/useTranslation'

import type { DropdownItem } from '@circlefin/components/lib/Dropdown'
import type { SelectListItem } from '@circlefin/components/lib/SelectList'

/**
 * Props for the ScopeSelector component.
 */
export interface ScopeSelectorProps {
  /**
   * The list of items to choose from.
   */
  items: SelectListItem<string>[]
  /**
   * The currently selected scope value.
   */
  currentScope: string
  /**
   * Callback function called when a new scope is selected.
   */
  onScopeChange: (scope: string) => void
}

/**
 * ScopeSelector component provides a dropdown to select a scope from a list of options.
 * It uses the Downshift library for accessibility and state management.
 * @param props - The component props.
 * @returns A React component rendering a scope selector dropdown.
 */
export const ScopeSelector: React.FC<ScopeSelectorProps> = ({
  items,
  currentScope,
  onScopeChange,
}) => {
  const { t } = useTranslation('playground')

  const handleItemChange = useCallback(
    (item: DropdownItem<string> | null) => {
      if (item?.value) {
        onScopeChange(item.value)
      }
    },
    [onScopeChange],
  )

  return (
    <div className="w-full">
      <div className="w-full relative">
        <Dropdown
          items={items}
          label={t`product`}
          onChange={handleItemChange}
          value={currentScope}
        >
          <Dropdown.Options items={items} />
        </Dropdown>
      </div>
    </div>
  )
}
