'use client'

import { useCallback, useMemo, useState } from 'react'

import { Button } from '@circlefin/components/lib/Button'
import { ComponentGroup } from '@circlefin/components/lib/ComponentGroup'
import { Icon } from '@circlefin/components/lib/Icon'
import { useController } from '@circlefin/form'
import { GENERATE_ENTITY_SECRET_CIPHER_TEXT } from '@features/playground.constants/operations'
import { ExecuteOperationDocument } from '@features/playground.graphql'
import { useGlobalPlaygroundProjectSettings } from '@features/playground.hooks/useGlobalPlaygroundProjectSettings'
import { usePlaygroundState } from '@features/playground.hooks/usePlaygroundState'
import {
  getApiKeySchema,
  getEntitySecretSchema,
} from '@features/playground.validation'
import { useMutation } from '@shared/apollo'
import get from 'lodash.get'
import useTranslation from 'next-translate/useTranslation'

import { StringInputField } from '../StringInputField/StringInputField'

import { ConfigurationModal } from './ConfigurationModal/ConfigurationModal'

import type { StringInputFieldProps } from '../StringInputField/StringInputField'

/**
 * Props for the EntitySecretCipherTextInputField component.
 */
export type EntitySecretCipherTextInputFieldProps = StringInputFieldProps

/**
 * A specialized input field for handling entity secret ciphertexts.
 *
 * This component integrates with global playground settings to determine
 * if it is properly configured. It provides options to configure settings
 * or generate a ciphertext based on the current configuration.
 * @param props - The properties for the EntitySecretCipherTextInputField component.
 * @returns The rendered EntitySecretCipherTextInputField component.
 */
export const EntitySecretCipherTextInputField: React.FC<
  EntitySecretCipherTextInputFieldProps
> = (props) => {
  const { t } = useTranslation('playground')
  const [settings] = useGlobalPlaygroundProjectSettings()
  const { operation } = usePlaygroundState()
  const [executeOperation, { loading }] = useMutation(
    ExecuteOperationDocument,
    { onError: () => {} },
  )
  const { field } = useController({
    name: props.prefix ? `${props.prefix}.${props.name}` : props.name,
  })

  const groupSettings: Record<string, string | number | undefined> =
    settings[operation.group]

  /**
   * Determines if the component is properly configured by validating
   * the API key and entity secret against their respective schemas.
   */
  const isConfigured = useMemo(() => {
    // Ensure both API key and entity secret exist and are valid
    return (
      getApiKeySchema().isValidSync(get(groupSettings, 'apiKey', '')) &&
      getEntitySecretSchema().isValidSync(
        get(groupSettings, 'entitySecret', ''),
      )
    )
  }, [groupSettings])

  const [showModal, setShowModal] = useState(false)

  /**
   * Handles the click event for the "Configure" button.
   * Opens the configuration modal.
   */
  const handleConfigureClick = useCallback(() => {
    setShowModal(true)
  }, [])

  /**
   * Handles the click event for the "Generate" button.
   * Executes the custom operation to generate ciphertext.
   */
  const handleGenerateClick = useCallback(() => {
    if (isConfigured) {
      void executeOperation({
        variables: {
          input: {
            identifier: GENERATE_ENTITY_SECRET_CIPHER_TEXT,
            bearer: groupSettings.apiKey?.toString(),
            requestBody: {
              entitySecret: groupSettings.entitySecret?.toString(),
            },
          },
        },
        onCompleted: (data) => {
          // add content to field
          field.onChange(
            get(data.executeOperation.body, 'entitySecretCipherText', ''),
          )
        },
        onError: () => {},
      })
    }
  }, [executeOperation, field, isConfigured, groupSettings])

  /**
   * Handles the closing of the configuration modal.
   */
  const handleModalClose = useCallback(() => {
    setShowModal(false)
  }, [])

  return (
    <div className="w-full relative flex items-end space-x-4 overflow-hidden">
      <ComponentGroup className="[&>*]:static flex-1">
        <StringInputField {...props} />
        <Button
          className="mt-8"
          loading={loading}
          onClick={isConfigured ? handleGenerateClick : handleConfigureClick}
          type="button"
          variant="primary"
        >
          {isConfigured ? t('form.generate') : t('form.configure')}
        </Button>
      </ComponentGroup>
      {isConfigured && (
        <Button
          className="flex-shrink-0"
          label={t('form.configure')}
          onClick={handleConfigureClick}
          variant="primary"
          iconOnly
        >
          <Icon name="CogSolid" />
        </Button>
      )}
      <ConfigurationModal
        onClose={handleModalClose}
        onCloseStart={handleModalClose}
        open={showModal}
      />
    </div>
  )
}
