'use client'

import type React from 'react'
import { useCallback, useMemo } from 'react'

import { Dropdown } from '@circlefin/components/lib/Dropdown'
import { SnippetConfig } from '@features/playground.constants/snippets'
import useTranslation from 'next-translate/useTranslation'

import type { SelectListItem } from '@circlefin/components/lib/SelectList'

/**
 * Interface representing the value of a client selection.
 */
export interface ClientSelectionValue {
  /**
   * The selected client.
   */
  client: string
  /**
   * The programming language of the selected client.
   */
  lang: string
}

/**
 * Generate dropdown items from SnippetConfig.
 */
const items: SelectListItem<ClientSelectionValue>[] = Object.keys(
  SnippetConfig,
).flatMap((lang) =>
  SnippetConfig[lang as keyof typeof SnippetConfig].clients.map((client) => ({
    // This label will be replaced before rendering
    label: '',
    value: { lang, client },
  })),
)

export interface ClientSelectionProps {
  /**
   * The currently selected client and language.
   */
  value: ClientSelectionValue
  /**
   * Callback function triggered when a new client is selected.
   */
  onClientSelection: (value: ClientSelectionValue) => void
}

/**
 * ClientSelection component for selecting a client and language.
 */
export const ClientSelection: React.FC<ClientSelectionProps> = ({
  value,
  onClientSelection,
}) => {
  const { t } = useTranslation('playground')

  /**
   * Handle selection change.
   */
  const handleClientSelection = useCallback(
    (item: SelectListItem<ClientSelectionValue>) => {
      if (item.value) {
        onClientSelection(item.value)
      }
    },
    [onClientSelection],
  )

  /**
   * Memoized array of mapped items for the dropdown.
   */
  const mappedItems = useMemo(() => {
    return items
      .map((item) => {
        /**
         * We are iterating over a known set and therefore know that the value will be defined.
         */
        const value = item.value!
        return {
          ...item,
          label: (
            <span className="block w-full text-sm">
              <strong className="inline-block">
                {t(`snippet.target.${value.lang}`)}
              </strong>
              <span className="inline-block mx-1">-</span>
              <span>{t(`snippet.client.${value.lang}.${value.client}`)}</span>
            </span>
          ) as unknown as string, // Type assertion needed due to Dropdown component limitations
        }
      })
      .filter(Boolean) as SelectListItem<ClientSelectionValue>[]
  }, [t])

  return (
    <Dropdown
      className="w-96 text-sm"
      items={mappedItems}
      onChange={handleClientSelection}
      value={value}
      variant="hug-contents"
    >
      <Dropdown.Options items={mappedItems} />
    </Dropdown>
  )
}
