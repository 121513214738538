import { useCallback } from 'react'

import { useModalHistory } from '@circlefin/modal-router'
import { FullScreenPlayground } from '@features/playground.containers/FullScreenPlayground'

import type { SelectedOperation } from '@features/playground.containers/ApiReferenceNavigation/types'

export const InteractivePlayground: React.FC = () => {
  const modalRouter = useModalHistory()

  const handleOperationSelection = useCallback(
    (operation?: SelectedOperation) => {
      if (modalRouter.route) {
        modalRouter.replace({
          ...modalRouter.route,
          query: {
            operation,
          },
        })
      }
    },
    [modalRouter],
  )

  return (
    <div className="w-full h-screen overflow-hidden">
      <FullScreenPlayground
        onOperationSelection={handleOperationSelection}
        selectedOperation={
          modalRouter.route?.query?.operation as SelectedOperation
        }
      />
    </div>
  )
}
