import { useCallback, useEffect, useState } from 'react'

import { ScrollArea } from '@circlefin/components/lib/ScrollArea'
import { SkeletonBox } from '@circlefin/components/lib/SkeletonBox'
import { ScopeSelector } from '@features/playground.components/ScopeSelector'
import { SearchField } from '@features/playground.components/SearchField'
import { API_REFERENCE_PREFIX } from '@features/playground.constants'
import { OperationGroupsDocument } from '@features/playground.graphql'
import { useQuery } from '@shared/apollo'
import classNames from 'classnames'
import useTranslation from 'next-translate/useTranslation'

import { ApiReferenceDesktopNavigation } from '../../ApiReferenceNavigation/ApiReferenceDesktopNavigation/ApiReferenceDesktopNavigation'

import type { SelectedOperation } from '../../ApiReferenceNavigation/types'

const placeHolderAction = () => null

/**
 * Props for the Sidebar component.
 */
export interface SidebarProps {
  /**
   * The currently selected operation identifier.
   * If undefined, no operation is selected.
   */
  selectedOperation?: SelectedOperation
  /**
   * Callback function to handle operation selection.
   * @param operation - The summary of the selected operation.
   */
  onOperationSelection: (operation?: SelectedOperation) => void
}

/**
 * Sidebar component for the API playground.
 * It manages scope selection, search functionality, and operation selection.
 * @param props - The component props.
 * @returns A React component rendering the sidebar for the API playground.
 */
export const Sidebar: React.FC<SidebarProps> = ({
  selectedOperation,
  onOperationSelection,
}) => {
  const { t } = useTranslation('common')
  const { data, loading } = useQuery(OperationGroupsDocument, {
    onError: () => {},
  })
  const [currentScope, setCurrentScope] = useState<string | null>(null)
  const [query, setQuery] = useState('')
  const [isScrolled, setIsScrolled] = useState(false)

  const handleScopeChange = useCallback(
    (item: string) => {
      setCurrentScope(item)
      onOperationSelection()
    },
    [onOperationSelection],
  )

  const handleOperationSelection = useCallback(
    (operation?: SelectedOperation) => {
      onOperationSelection(operation)
    },
    [onOperationSelection],
  )

  useEffect(() => {
    if (data && currentScope === null) {
      setCurrentScope(data.operationGroups[0].key)
    }
  }, [data, currentScope])

  const group = data?.operationGroups.find(
    (group) => group.key === currentScope,
  )

  const handleScroll = useCallback((values: { top: number }) => {
    setIsScrolled(values.top > 0)
  }, [])

  return (
    <div className="w-full h-full" data-testid="playground-sidebar">
      <ScrollArea
        className="w-full h-full [&_.track-vertical]:z-30 [&_.track-vertical]:right-0"
        onUpdate={handleScroll}
        overflowMask={false}
      >
        {data && currentScope && group && (
          <div className="-mr-4">
            <div
              className={classNames(
                'w-full sticky px-4 p-6 top-0 flex items-center justify-center flex-col gap-4 z-20 bg-white',
                {
                  'shadow-md': isScrolled,
                },
              )}
              data-testid="sidebar-header"
            >
              <ScopeSelector
                currentScope={currentScope}
                items={data.operationGroups.map((group) => ({
                  label: group.name,
                  value: group.key,
                }))}
                onScopeChange={handleScopeChange}
              />
              <SearchField onQueryChange={setQuery} query={query} />
            </div>
            <ApiReferenceDesktopNavigation
              backButtonLabel={t`allDocs`}
              className="px-4"
              currentScope=""
              handleScopeChange={placeHolderAction}
              onOperationSelection={handleOperationSelection}
              prefix={API_REFERENCE_PREFIX}
              query={query}
              selectedOperation={selectedOperation}
              setQuery={placeHolderAction}
            />
          </div>
        )}
        {loading && (
          <div className="w-full">
            <div className="w-full px-6 py-4 border-b border-neutral-subtle">
              <SkeletonBox className="h-10 w-full" loading />
            </div>
            <div className="px-6 py-4">
              <SkeletonBox className="h-4 w-4/5 mb-1" loading />
              <SkeletonBox className="h-12 w-full mb-4" loading />
              <SkeletonBox className="h-12 w-full" loading />
            </div>
          </div>
        )}
      </ScrollArea>
    </div>
  )
}
