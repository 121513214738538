import type { FC } from 'react'

import { CWButton } from '@features/common.components/ComponentsWeb/Button'
import { CWIcon } from '@features/common.components/ComponentsWeb/Icon'
import { NavItem } from '@features/navigation.components/Navigation/NavItem'
import { ScopeSelector } from '@features/playground.components/ScopeSelector'
import { SearchField } from '@features/playground.components/SearchField'
import classNames from 'classnames'
import Link from 'next/link'
import { usePathname } from 'next/navigation'

import { ProjectList } from '../ProjectList/ProjectList'

import type { ApiReferenceChildProps } from '../ApiReferenceNavigation'

export const ApiReferenceSharedNavigation: FC<ApiReferenceChildProps> = ({
  additionalNavigationItems,
  operationGroups,
  currentScope,
  selectedOperation,
  handleScopeChange,
  query,
  setQuery,
  className,
  link,
  prefix,
  onOperationSelection,
  backButtonLabel,
  fallbackBackUrl = '/',
}) => {
  const pathname = usePathname()

  const currentGroup =
    operationGroups?.find((group) => group.key === currentScope) ??
    operationGroups?.[0]

  const isPathInAdditionalNavItems = additionalNavigationItems?.some(
    (item) => item.url === pathname,
  )

  const backUrl = isPathInAdditionalNavItems
    ? fallbackBackUrl
    : (additionalNavigationItems?.[0]?.url ?? fallbackBackUrl)

  return (
    <>
      <>
        <Link className="block px-2" href={backUrl}>
          <CWButton
            className="pl-0"
            label={backButtonLabel}
            size="base"
            variant="text"
          >
            <CWIcon className="mr-2" name="ArrowNarrowLeftSolid" size={24} />
            {backButtonLabel}
          </CWButton>
        </Link>
        {currentGroup && (
          <div className="type-h-title-md px-2 mb-6 mt-2">
            {currentGroup.name}
          </div>
        )}
      </>
      <div className={classNames('w-full', className)}>
        {operationGroups && currentScope && (
          <div
            className={classNames(
              'w-full pb-6 flex items-center justify-center flex-col gap-2',
            )}
            data-testid="sidebar-header"
          >
            <ScopeSelector
              currentScope={currentScope}
              items={operationGroups.map((group) => ({
                label: group.name,
                value: group.key,
              }))}
              onScopeChange={handleScopeChange}
            />
            <SearchField onQueryChange={setQuery} query={query} />
          </div>
        )}
        {additionalNavigationItems && (
          <div className="border-b border-neutral-subtle mb-6 pb-6 w-full">
            {additionalNavigationItems.map((item) => (
              <NavItem
                key={item.label}
                className={classNames(
                  'p-2 transition-gradient duration-300 w-full block text-neutral hover:bg-primary-subtle-hovered',
                  {
                    'bg-primary-subtle-hovered font-circular-bold':
                      pathname === item.url,
                  },
                )}
                href={item.url}
                label={item.label}
              />
            ))}
          </div>
        )}
        <ProjectList
          link={link}
          onOperationSelection={onOperationSelection}
          prefix={prefix}
          projects={currentGroup?.projects ?? []}
          query={query}
          selectedOperation={selectedOperation}
        />
      </div>
    </>
  )
}
