'use client'

import { useCallback, useEffect } from 'react'

import { FixedBanner } from '@circlefin/components/lib/FixedBanner'
import { Modal } from '@circlefin/components/lib/Modal'
import { useForm } from '@circlefin/form/useForm/useForm'
import { useGlobalPlaygroundProjectSettings } from '@features/playground.hooks/useGlobalPlaygroundProjectSettings'
import { usePlaygroundState } from '@features/playground.hooks/usePlaygroundState'
import {
  getApiKeySchema,
  getEntitySecretSchema,
} from '@features/playground.validation'
import useTranslation from 'next-translate/useTranslation'
import * as y from 'yup'

import type { ModalProps } from '@circlefin/components/lib/Modal'
import type { SubmitHandler } from '@circlefin/form/useForm'

/**
 * Props for the ConfigurationModal component.
 * Extends the standard Modal props.
 */
export type ConfigurationModalProps = ModalProps & {
  /**
   * Callback that will be invoked, when the Modal should be closed.
   */
  onClose: () => void
}

/**
 * Yup schema for the configuration form.
 */
export const formSchema = y.object({
  apiKey: getApiKeySchema(),
  entitySecret: getEntitySecretSchema(),
})

/**
 * Type definition for the form values, inferred from the form schema.
 */
type FormValues = y.InferType<typeof formSchema>

/**
 * ConfigurationModal component.
 *
 * This component renders a modal that allows users to configure their API credentials,
 * including the API Key and Entity Secret. It uses the global playground settings
 * to pre-fill the form and update the settings upon submission.
 * @returns The rendered ConfigurationModal component.
 */
export const ConfigurationModal: React.FC<ConfigurationModalProps> = ({
  onClose,
  ...props
}) => {
  const { t } = useTranslation('common')
  const { operation } = usePlaygroundState()
  const [settings, updateProjectSettings] = useGlobalPlaygroundProjectSettings()

  const groupSettings: Record<string, string | number | undefined> =
    settings[operation.group] ?? {}

  const [Form, { setValue }] = useForm<FormValues>({
    schema: formSchema,
    defaultValues: {
      apiKey: groupSettings.apiKey?.toString() ?? '',
      entitySecret: groupSettings.entitySecret?.toString() ?? '',
    },
  })

  useEffect(() => {
    setValue('apiKey', groupSettings.apiKey?.toString() ?? '')
  }, [groupSettings.apiKey, setValue])

  /**
   * Handles the form submission.
   * Updates the project settings with the new values.
   * @param values - The form values to be submitted.
   */
  const handleSubmit: SubmitHandler<FormValues> = useCallback(
    (values) => {
      updateProjectSettings(operation.group, values)
      onClose()
    },
    [updateProjectSettings, operation.group, onClose],
  )

  return (
    <Modal data-testid="configuration-modal" {...props}>
      <Modal.Title>{t`playground:playground.configurationModal.title`}</Modal.Title>
      <Modal.Subtitle>
        {t`playground:playground.configurationModal.subtitle`}
      </Modal.Subtitle>
      <Modal.Body className="w-full space-y-4 pt-4">
        <p>{t`playground:playground.configurationModal.body.0`}</p>
        <p>{t`playground:playground.configurationModal.body.1`}</p>
        <FixedBanner status="info">
          <FixedBanner.Description>
            {t`playground:playground.configurationModal.banner`}
          </FixedBanner.Description>
        </FixedBanner>
        <Form className="space-y-4" onSubmit={handleSubmit}>
          <Form.Input
            label={t`playground:playground.configurationModal.labels.apiKey`}
            name="apiKey"
          />
          <Form.Input
            label={t`playground:playground.configurationModal.labels.entitySecret`}
            name="entitySecret"
          />
          <Form.SubmitButton variant="primary">{t`playground:playground.configurationModal.labels.configure`}</Form.SubmitButton>
        </Form>
      </Modal.Body>
    </Modal>
  )
}
