'use client'

import { type FC } from 'react'

import { Button } from '@circlefin/components/lib/Button'
import { Icon } from '@circlefin/components/lib/Icon'
import { Sidenav } from '@circlefin/components/lib/Sidenav'
import { useFeatureFlag } from '@features/flags.hooks/useFeatureFlag'
import classNames from 'classnames'
import Link from 'next/link'
import { usePathname } from 'next/navigation'

import { NavGroup } from '../NavGroup/NavGroup'
import { NavIconItem } from '../NavIconItem/NavIconItem'
import { NavItem } from '../NavItem/NavItem'
import { NavSection } from '../NavSection/NavSection'

import type { NavigationProps } from '../types'

export interface NavigationPropsWithContentWrapper extends NavigationProps {
  /**
   * ID of the wrapper that holds the actual content.
   * Is needed to calculate any offset that needs to be applied.
   */
  contentWrapperId: string
}

export const DesktopNavigation: FC<NavigationPropsWithContentWrapper> = ({
  title,
  parent,
  items,
}) => {
  const pathname = usePathname()
  const migrationEnabled = useFeatureFlag('migrationEnabled')
  const sdkAndSampleOverviewEnabled = useFeatureFlag(
    'sdkAndSampleOverviewEnabled',
  )

  return (
    <div
      className={classNames(
        'hidden md:block md:fixed bottom-0 left-0 border-r border-neutral-subtle bg-black-50',
        migrationEnabled ? 'top-header' : 'top-20',
      )}
    >
      <Sidenav
        aria-label="example"
        className="gap-0 overflow-y-auto bg-white p-6 w-sidebar"
        variant="light"
      >
        {parent ? (
          <Link href={parent.url}>
            <Button
              className="pl-0"
              label={parent.label}
              size="base"
              variant="text"
            >
              <Icon className="mr-2" name="ArrowNarrowLeftSolid" size={24} />
              {parent.label}
            </Button>
          </Link>
        ) : null}
        {title ? <p className="type-h-title-md mt-2">{title}</p> : null}
        {items.map((item, index) => {
          if ('isHidden' in item && item.isHidden) return null

          // Hide the 'Resources' section on desktop home screen when sdkAndSampleOverviewEnabled is enabled
          if (
            sdkAndSampleOverviewEnabled &&
            pathname === '/' &&
            item.label === 'Resources'
          ) {
            return null
          }

          if ('items' in item || 'children' in item) {
            if ('items' in item) {
              return (
                <NavSection
                  key={item.label + index}
                  section={{
                    ...item,
                    // We want to filter out some items on the desktop home screen when sdkAndSampleOverviewEnabled is disabled
                    items: item.items.filter((child) =>
                      shouldShowChild(pathname, item.label, child.label),
                    ),
                  }}
                />
              )
            }
            if ('children' in item) {
              return <NavGroup key={index} index={index} item={item} />
            }
          } else {
            return item.icon ? (
              <NavIconItem
                key={`${item.url}-${index}`}
                className="p-0.5 mb-2 hover:border-blue-300"
                href={item.url.toLowerCase()} // temporary solution until we standardize the naming of 'quickstarts'
                icon={item.icon}
                label={item.label}
              />
            ) : (
              <NavItem
                key={`${item.url}-${index}`}
                className={classNames(
                  'p-0.5 transition-gradient duration-300',
                  {
                    'border-blue-300 font-circular-bold': item.url === pathname,
                  },
                  {
                    'hover:bg-primary-subtle-hovered': item.url !== pathname,
                  },
                )}
                href={item.fallbackUrl ?? item.url}
                label={item.label}
              />
            )
          }
        })}
      </Sidenav>
    </div>
  )
}

/**
 * Hide some of the items in `Resources` section when the section is shown on desktop home screen since they are already shown on the header.
 * @param pathname - The current pathname.
 * @param parentLabel - The parent navigation item label to check.
 * @param childLabel - The child navigation item to check.
 * @returns A boolean indicating whether the child should be shown.
 */
const shouldShowChild = (
  pathname: string,
  parentLabel: string,
  childLabel: string,
) => {
  return pathname === '/' && parentLabel === 'Resources'
    ? !['API Reference', 'Help'].includes(childLabel)
    : true
}
