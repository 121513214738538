'use client'

import { useCallback, type FC } from 'react'

import { Icon, Tag } from '@circlefin/components'
import { useModal } from '@circlefin/modal-router'
import DOMPurify from 'dompurify'
import Link from 'next/link'

import type { IconProps } from '@circlefin/components/lib/Icon'

/**
 * Represents the HTTP methods supported by this component.
 */
export type ResultItemApiMethods = 'get' | 'post' | 'patch' | 'put' | 'delete'

export interface ResultItemProps {
  /**
   * The title of the result item.
   */
  title: string
  /**
   * The description of the result item.
   */
  description: string
  /**
   * The URL of the result item.
   */
  href: string
  /**
   * The options for the result item. Can be either:
   * - `{ type: 'docs' | 'video' }`: Indicates the result item is a documentation or video.
   * - `{ type: 'api'; method: {@link ResultItemApiMethods} }`: Indicates the result item is an API endpoint with a HTTP method.
   */
  options:
    | { type: 'docs' | 'video' | 'Python' | 'Node.js' }
    | { type: 'api'; method: ResultItemApiMethods }
}

/**
 * A mapping of result item options to their corresponding icon names.
 */
const iconMapping: Record<
  Exclude<ResultItemProps['options']['type'], 'Python' | 'Node.js'>,
  IconProps['name']
> = {
  docs: 'BookOpenOutline',
  video: 'Social/Youtube',
  api: 'CodeOutline',
}

export const ResultItem: FC<ResultItemProps> = ({
  title,
  description,
  href,
  options,
}) => {
  const { close } = useModal()

  const handleClick = useCallback(() => {
    close()
  }, [close])

  return (
    <Link href={href}>
      <div
        className="hover:bg-black-25 py-3 rounded-xs space-y-1"
        onClick={handleClick}
      >
        <div className="flex items-center space-x-2">
          {options.type !== 'Python' && options.type !== 'Node.js' ? (
            <Icon name={iconMapping[options.type]} size={22} />
          ) : null}
          {['api', 'Python', 'Node.js'].includes(options.type) ? (
            <Tag
              className="uppercase rounded-md bg-bold-primary text-invert font-circular-bold text-xs"
              variant="neutral"
            >
              {options.type === 'api' ? options.method : options.type}
            </Tag>
          ) : null}
          <div
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(title) }}
            className="type-body-base-bold"
          />
        </div>
        <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(description),
          }}
          className="type-body-sm line-clamp-2"
        />
      </div>
    </Link>
  )
}
