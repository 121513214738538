import Markdown from 'react-markdown'

import { Button } from '@circlefin/components/lib/Button'
import { Icon } from '@circlefin/components/lib/Icon'
import { Tooltip } from '@circlefin/components/lib/Tooltip'
import classNames from 'classnames'

import type { Field } from '@shared/openapi/types'

/**
 * Interface for the input parameters of getInputLabel function.
 */
interface GetInputLabelParams {
  /**
   * Schema of the field.
   */
  schema: Field.FieldType
  /**
   * Optional: The name of this property, which will serve as the label. If not provided, the title will be used as a fallback.
   */
  name?: string
  /**
   * Optional flag to render the label as disabled.
   */
  disabled?: boolean
}

/**
 * Generates a label for an input field, optionally including a required indicator
 * and typecasts it to a string to satisfy the input type constraints.
 * @param params - The parameters for generating the label.
 * @returns The generated label as a React node.
 */
export const getInputLabel = ({
  name,
  schema,
  disabled = false,
}: GetInputLabelParams) => {
  if (schema.type === 'any' || schema.type === 'anyOf') return name

  const displayName = () => {
    const newName = name || schema.title

    if (newName?.includes('.oneOf-')) {
      return newName.split('.')[0]
    }

    return newName
  }

  return (
    <span className="flex items-center justify-start h-7">
      <span className={classNames({ 'opacity-75': disabled })}>
        {displayName()}
      </span>
      {schema.required === true && (
        <span
          className="text-error inline-block ml-0.5"
          data-testid="required-indicator"
        >
          *
        </span>
      )}
      {schema.description && (
        <Tooltip
          className="z-60"
          closeEvents={['mouseleave']}
          content={
            <Markdown className="[&_code]:break-words">
              {schema.description}
            </Markdown>
          }
          openEvent="mouseenter"
          place="right"
          size="default"
          width="default"
        >
          <Button
            className="text-icon-neutral p-1 ml-1"
            data-testid="tooltip-input-label"
            variant="text"
          >
            <Icon name="QuestionMarkCircleOutline" size={20} />
          </Button>
        </Tooltip>
      )}
    </span>
  ) as unknown as string
}
